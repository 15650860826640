import {
    Children,
    cloneElement,
    lazy,
    Suspense,
    useEffect,
    useState,
} from 'react';

import { Helmet } from 'react-helmet-async';

import { checkEnv } from '../utils/Utils';
import { logError } from '../utils/SiteHelpers';
import publicAxios from '../utils/api';
import Header from './includes/header/Header';
import FooterSkeleton from './skeleton/Footer/FooterSkeleton';
import HeaderSkeleton from './skeleton/header/HeaderSkeleton';

const Footer = lazy(() => import('./includes/footer/Footer'));

const Layout = ({ children }) => {
    const [isMobile, setIsMobile] = useState(() => window.innerWidth < 768); //set device is mobile or desktop

    const [siteSetting, setSiteSetting] = useState([]); // site settings
    const [adminUrl, setAdminUrl] = useState([]); // admin url to get path for media
    const [favIcon, setFavIcon] = useState();
    const isProduction = checkEnv();
    const [headerLogo, setHeaderLogo] = useState([]);
    const [googleAnalyticsId, setGoogleAnalyticsId] = useState('');

    const getSiteSetting = async () => {
        const { data } = await publicAxios.get(`public-settings`);
        const siteSetting = data.data.siteSetting;
        const adminUrl = data.data.url;
        const googleAnalytic = data.data.googleAnalytic;

        setSiteSetting(siteSetting);
        setAdminUrl(adminUrl);
        setFavIcon(
            data.data.url + '/images/header/' + siteSetting.public_fav_icon,
        );

        if (googleAnalytic && googleAnalytic.value !== 'undefined') {
            // document.head.innerHTML =
            //     document.head.innerHTML + googleAnalytic.value;
            setGoogleAnalyticsId(googleAnalytic.value);
        }

        // After site settings are available, set isMobile based on window width
        setIsMobile(window.innerWidth < 768);
    };

    const getLogo = async () => {
        try {
            const { data } = await publicAxios.get(
                isMobile ? 'get-mobile-logo' : 'get-desktop-logo',
            );
            setHeaderLogo(data);
        } catch (error) {
            // Handle error appropriately
            logError('Error fetching logo:', error);
        }
    };

    useEffect(() => {
        getSiteSetting();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize(); // Check initial size
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getLogo();
    }, [isMobile]);

    return (
        <>
            <Helmet>
                <link
                    rel="icon"
                    type="image/png"
                    href={favIcon}
                    sizes="16x16"
                />
                {isProduction && googleAnalyticsId && (
                    <>
                        <script
                            async
                            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
                        />
                        <script>
                            {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${googleAnalyticsId}');
                            `}
                        </script>
                    </>
                )}
            </Helmet>
            {/* Header */}
            <Suspense fallback={<HeaderSkeleton />}>
                <Header
                    siteSetting={siteSetting}
                    adminUrl={adminUrl}
                    isMobile={isMobile}
                    headerLogo={headerLogo}
                />
            </Suspense>

            {/* Main content */}
            <main>
                {Children.map(children, (child) =>
                    cloneElement(child, {
                        siteSetting: siteSetting, // Pass the siteSetting prop to children
                        headerLogo: headerLogo, // Pass the desktopLogo prop to children
                        isMobile: isMobile,
                    }),
                )}
            </main>

            {/* Footer */}
            <Suspense fallback={<FooterSkeleton />}>
                <Footer siteSetting={siteSetting} />
            </Suspense>
        </>
    );
};

export default Layout;
