export const defaultLeftHeaderContent = [
    {
        key: 'email',
        name: 'Google Gmail',
        favicon: '/assets/images/communicator_icons/gmail.png',
        url: 'https://www.gmail.com',
    },
    {
        key: 'calendar',
        name: 'Google Calendar',
        favicon: '/assets/images/communicator_icons/googlecalender.png',
        url: 'https://calendar.google.com',
    },
    {
        key: 'google_messages_web',
        name: 'Google Messages',
        favicon:
            '/assets/images/communicator_icons/google_messages_web.png',
        url: 'https://messages.google.com/web',
    },
    {
        key: 'cloud_drive',
        name: 'Google Drive',
        favicon: '/assets/images/communicator_icons/drive.png',
        url: 'https://drive.google.com',
    },
    {
        key: 'google_meet',
        name: 'Google Meet',
        favicon: '/assets/images/communicator_icons/google_meet.png',
        url: 'https://meet.google.com',
    },
    {
        key: 'dynamics365',
        name: 'Dynamics 365',
        favicon: '/assets/images/communicator_icons/microsoft365.png',
        url: 'https://www.microsoft365.com/',
    },
    {
        key: 'mscopilot',
        name: 'Microsoft CoPilot',
        favicon: '/assets/images/communicator_icons/ms_copilot.png',
        url: 'https://copilot.microsoft.com/',
    },
    {
        key: 'microsoft_teams',
        name: 'Microsoft Teams',
        favicon:
            '/assets/images/communicator_icons/microsoft_teams.png',
        url: 'https://teams.microsoft.com/',
    },
    {
        key: 'linkedin',
        name: 'LinkedIn',
        favicon: '/assets/images/communicator_icons/linkedin.png',
        url: 'https://www.linkedin.com/',
    },
    {
        key: 'paypal',
        name: 'PayPal',
        favicon: '/assets/images/communicator_icons/paypal.png',
        url: 'https://www.paypal.com/pk/home',
    },
    {
        key: 'calcom_video',
        name: 'Cal.com Video',
        favicon: '/assets/images/communicator_icons/cal.png',
        url: 'https://cal.com/resources/feature/cal-video',
    },
    {
        key: "chatgpt",
        name: "OpenAI ChatGPT",
        favicon: "/assets/images/communicator_icons/chatgpt.png",
        url: "https://chatgpt.com",
    },
    {
        key: 'stripe',
        name: 'Stripe',
        favicon: '/assets/images/communicator_icons/stripe.png',
        url: 'https://stripe.com/',
    },
    {
        key: 'whatsapp',
        name: 'WhatsApp',
        favicon: '/assets/images/communicator_icons/whatsapp.png',
        url: 'https://whatsapp.com',
    },
    {
        key: 'zoom_workplace',
        name: 'Zoom Workplace',
        favicon: '/assets/images/communicator_icons/zoom_video.png',
        url: 'https://www.zoom.com',
    },
].map((header, index) => ({
    ...header,
    id: index + 1,
}))

/**
 * Generates an array of 15 empty header objects.
 *
 * @returns {Array<{id: number, key: string, name: string, favicon: string, url: string}>} 
 * An array of objects with default values.
 */
export const generateDefaultLeftHeadersEmpty = () => {
    // const defaultHeaders = [];

    // for (let i = 0; i < 15; i++) {
    //     defaultHeaders.push({
    //         id: i + 1,
    //         key: 'customize',
    //         name: 'Customize',
    //         favicon: "/assets/images/default_hlt.png",
    //         url: '',
    //     });
    // }

    // return defaultHeaders;
    return defaultLeftHeaderContent
}